import React from 'react'
import Layout from '../../layouts/Layout'
import BasicHero from '../../components/Hero/BasicHero'

import { Container } from 'theme-ui'

export default function news() {
  return (
    <Layout theme={'dark'}>
      <BasicHero heading="News" />
      <Container
        sx={{
          flexDirection: 'column',
          p: {},
        }}
      >
        <h2
          sx={{
            textAlign: 'left',
            mb: [3, null, null, 0],
          }}
        >
          Curo Group Rebrands as Attain Finance
        </h2>{' '}
        <em sx={{ mb: 3 }}>
          - Rebranding Aligns with Company’s Evolving Vision and
          Customer-Centric Focus -
        </em>
        <p>
          <b> Greenville, South Carolina, February 5, 2025</b> – Curo Group
          Holdings, LLC (“Curo” or the “Company”), a leading consumer finance
          company, today announced that, effective immediately, it has rebranded
          the Company to Attain Finance.
        </p>{' '}
        <p>
          The rebranding is the latest milestone in the Company’s growth and
          evolution, and underscores an enhanced focus on its commitment to
          customers and their financial goals through best-in-class financial
          solutions and exceptional service.
        </p>{' '}
        <p>
          “Our new name marks another exciting chapter in our journey, as we
          focus on delivering innovative financial solutions that help our
          customers thrive and attain financial security,” said Doug Clark,
          Chief Executive Officer of Attain Finance. “While our name is
          changing, our commitment to serve our customers remains unwavering.
        </p>{' '}
        <p>
          As part of the rebranding and continued alignment of the business,
          Attain Finance is consolidating its 390 US branches into one US brand
          name, Heights Finance, while maintaining its LendDirect and Cash Money
          brands in Canada through its 154 branches.
        </p>{' '}
        <p>
          “Attain Finance best represents our broader corporate intentions, the
          ambitions of our customers and our forward-thinking approach – to
          Attain,” added Mr. Clark. “Customers can expect the same high level of
          quality, integrity and reliability they’ve come to trust. We’re
          excited about the future and more committed than ever to delivering
          solutions that empower our customers, partners and communities.”
        </p>{' '}
        <p sx={{ m: 0 }}>
          <b> About Attain Finance </b>{' '}
        </p>{' '}
        <p>
          Attain Finance is a leading consumer credit lender serving U.S. and
          Canadian customers for over 50 years. Our roots in the consumer
          finance market run deep. We’ve worked diligently to provide customers
          a variety of convenient, easily accessible financial services. Our
          decades of diversified data power a hard - to - replicate underwriting
          and scoring engine, mitigating risk across the full spectrum of credit
          products. We operate under a number of brands including Cash Money®,
          LendDirect®, Heights Finance, Southern Finance, Covington Credit,
          Quick Credit and First Heritage Credit.
        </p>{' '}
        <p>
          For more information about Attain Finance, please visit{' '}
          <a href="https://www.attainfinance.com" title="Attain Finance">
            www.attainfinance.com
          </a>
          .
        </p>
        <p>
          Media Contact:
          <br />
          Email:{' '}
          <a href="mailto:IR@attainfinance.com" target="_blank">
            IR@attainfinance.com{' '}
          </a>{' '}
        </p>{' '}
      </Container>{' '}
    </Layout>
  )
}

// export const Head = ({ data }) => {
//   const page = data.contentfulPageJson
//   return <Seo title={page.pageTitle} />
// }
